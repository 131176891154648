/* Game Graph */
.dist_graph {margin:60px 0 0 24px; width:300px;}
.dist_graph .item {position:relative; margin-bottom:10px; width:300px; height:21px;}
.dist_graph dt {position:absolute; top:-4px; left:113px; width:74px; height:28px; background:url('../../../img/score/ui_game/sp_powerball_new.png') no-repeat 0 -9999px; text-indent:-9999px; z-index:5;}
.dist_graph .p_oe dt {background-position:0 -150px;}
.dist_graph .p_ou dt {background-position:0 -180px;}
.dist_graph .b_oe dt {background-position:0 -210px;}
.dist_graph .b_ou dt {background-position:0 -240px;}
.dist_graph .p_oe .left_on {background-position:-80px -150px;}
.dist_graph .p_oe .right_on {background-position:-160px -150px;}
.dist_graph .p_ou .left_on {background-position:-80px -180px;}
.dist_graph .p_ou .right_on {background-position:-160px -180px;}
.dist_graph .b_oe .left_on {background-position:-80px -210px;}
.dist_graph .b_oe .right_on {background-position:-160px -210px;}
.dist_graph .b_ou .left_on {background-position:-80px -240px;}
.dist_graph .b_ou .right_on {background-position:-160px -240px;}
.dist_graph .bar {position:relative; width:120px; height:20px; background:url('../../../img/score/ui_game/sp_dist_new.png') no-repeat 0 -9999px;}
.dist_graph .bar.left {float:left; background-position:0 0;}
.dist_graph .bar.right {float:right; background-position:right 0;}
.dist_graph .gauge {position:relative; width:0; height:20px; background:url('../../../img/score/ui_game/sp_dist_new.png') no-repeat 0 -9999px; transition:width 0.5s ease-in-out; z-index:1;}
.dist_graph .gauge:before {position:absolute; content:''; width:100%; height:20px; border-radius:10px; animation:animate-stripes2 0.5s linear infinite;
    background:url('../../../img/score/ui_game/bg_dist_gauge.png');}
.dist_graph .tx {position:absolute; top:0; height:20px; line-height:18px; font-family:Tahom, sans-serif; font-size:9px; color:#fff; z-index:2;}
.dist_graph .left .tx {left:0; padding-left:10px;}
.dist_graph .right .tx {right:0; padding-right:10px;}
.dist_graph .left .gauge {float:right; background-position:left -30px;}
.dist_graph .right .gauge {float:left; background-position:right -60px;}
.dist_graph .bms dt {width:28px;}
.dist_graph .bms dt.big {left:0; background-position:-240px -150px;}
.dist_graph .bms dt.middle {left:105px; background-position:-240px -180px;}
.dist_graph .bms dt.small {left:206px; background-position:-240px -210px;}

.dist_graph .bms .big_on dt.big {background-position:-270px -150px;}
.dist_graph .bms .middle_on dt.middle {background-position:-270px -180px;}
.dist_graph .bms .small_on dt.small {background-position:-270px -210px;}

.dist_graph .bms .bar {float:left; margin-left:35px; width:69px; background-position:right 0;}
.dist_graph .bms .bar.big {margin-left:23px;}
.dist_graph .bms .gauge {float:left;}

.dist_graph .bms .big .gauge {background-position:right -90px;}
.dist_graph .bms .middle .gauge {background-position:right -120px;}
.dist_graph .bms .small .gauge {background-position:right -150px;}
.dist_graph .bms .tx {right:0; padding-right:10px;}




.dist_graph_t {margin:57px 0 0 9px; width:322px;font-family:"Nanum Gothic";font-size:12px;font-weight:700;}
.dist_graph_t .item {position:relative; margin-bottom:9px; width:322px; height:12px;}
.dist_graph_t .item.p_oe {margin-top: 57px;}
.dist_graph_t .bms {display:flex; position:relative; margin-top:22px; width:324px; left: -1px; height:23px;}
.dist_graph_t .bms dd {display:flex;justify-content:space-around;width:100px;font-family:"Nanum Gothic";font-size:12px;font-weight:700;line-height:23px;color:#ffffff;}
.dist_graph_t .bms dd.middle {margin:0 12px;}
.dist_graph_t .bar {position:relative;float: left;top:1px;left:91px;width:130px;height:10px;border-radius:5px;overflow:hidden;}
.dist_graph_t .bar .gauge {position:absolute;width:130px;height:10px;animation: animate-stripes .5s linear infinite;}
.dist_graph_t .bar .gauge.right {right:-100%;/*background:red;*/background:url(../../../img/score/ui_game/3min_powerball/red_bar.png) no-repeat 0 0;}
.dist_graph_t .bar .gauge.left {left:-100%;/*background:#005AFF;*/background:url(../../../img/score/ui_game/3min_powerball/blue_bar.png) no-repeat 0 0; border-radius: 5px;}
.dist_graph_t .item .left_dt,.dist_graph_t .item .right_dt{display:flex;justify-content:space-between;position:absolute;top:-1px;width:62px;height:13px;color:#a1a1a1;}
.dist_graph_t .item .left_dt{float:left;left:20px;}
.dist_graph_t .item .right_dt{float:right;right:30px;flex-direction:row-reverse;}
.dist_graph_t .item .left_dt.on .odd, .dist_graph_t .item .left_dt.on .over {color:#00baff;}
.dist_graph_t .item .right_dt.on .even, .dist_graph_t .item .right_dt.on .under {color:#ff0000;}
.dist_graph_t .item dt .tx {color:#ffffff;}


.dist_graph dt {position:absolute; top:-4px; left:113px; width:74px; height:28px; background:url('../../../img/score/ui_game/sp_powerball_new.png') no-repeat 0 -9999px; text-indent:-9999px; z-index:5;}
.dist_graph .p_oe dt {background-position:0 -150px;}
.dist_graph .p_ou dt {background-position:0 -180px;}
.dist_graph .b_oe dt {background-position:0 -210px;}
.dist_graph .b_ou dt {background-position:0 -240px;}
.dist_graph .p_oe .left_on {background-position:-80px -150px;}
.dist_graph .p_oe .right_on {background-position:-160px -150px;}
.dist_graph .p_ou .left_on {background-position:-80px -180px;}
.dist_graph .p_ou .right_on {background-position:-160px -180px;}
.dist_graph .b_oe .left_on {background-position:-80px -210px;}
.dist_graph .b_oe .right_on {background-position:-160px -210px;}
.dist_graph .b_ou .left_on {background-position:-80px -240px;}
.dist_graph .b_ou .right_on {background-position:-160px -240px;}

.dist_graph .gauge:before {position:absolute; content:''; width:100%; height:20px; border-radius:10px; animation:animate-stripes2 0.5s linear infinite; background:url('../../../img/score/ui_game/bg_dist_gauge.png');}
.dist_graph .tx {position:absolute; top:0; height:20px; line-height:18px; font-family:Tahom, sans-serif; font-size:9px; color:#fff; z-index:2;}
.dist_graph .left .tx {left:0; padding-left:10px;}
.dist_graph .right .tx {right:0; padding-right:10px;}
.dist_graph .left .gauge {float:right; background-position:left -30px;}
.dist_graph .right .gauge {float:left; background-position:right -60px;}
.dist_graph .bms dt {width:28px;}
.dist_graph .bms dt.big {left:0; background-position:-240px -150px;}
.dist_graph .bms dt.middle {left:105px; background-position:-240px -180px;}
.dist_graph .bms dt.small {left:206px; background-position:-240px -210px;}

.dist_graph .bms .big_on dt.big {background-position:-270px -150px;}
.dist_graph .bms .middle_on dt.middle {background-position:-270px -180px;}
.dist_graph .bms .small_on dt.small {background-position:-270px -210px;}

.dist_graph .bms .bar {float:left; margin-left:35px; width:69px; background-position:right 0;}
.dist_graph .bms .bar.big {margin-left:23px;}
.dist_graph .bms .gauge {float:left;}

.dist_graph .bms .big .gauge {background-position:right -90px;}
.dist_graph .bms .middle .gauge {background-position:right -120px;}
.dist_graph .bms .small .gauge {background-position:right -150px;}
.dist_graph .bms .tx {right:0; padding-right:10px;}

.game_board_area .clock {
	height: 29px;
	line-height: 31px;
	text-align: center;
	font-family: Verdana, Arial, sans-serif;
	font-size: 12px;
	color: #c29b87
}

.game_board_area .link {
	height: 28px;
	text-align: center
}

.game_board_area .link a {
	display: inline-block;
	line-height: 28px;
	letter-spacing: 1px;
	font-family: Verdana, Arial, sans-serif;
	font-size: 12px;
	color: #c29b87
}

.cast_lots {
	position: relative;
	margin: 53px 0 6px 61px;
	width: 218px;
	height: 218px
}

.cast_lots .bg_board {
	position: absolute;
	top: 0;
	left: 0;
	width: 211px;
	height: 211px;
	background: url(../../../img/score/ui_game/glass_reflect.png) no-repeat 0 0
}

.cast_lots .bg_board:before {
	position: absolute;
	content: '';
	width: 211px;
	height: 211px;
	background: url(../../../img/score/ui_game/glass_reflect.png) no-repeat 0 0
}

.cast_lots .bg_animation span {
	display: block;
	width: 211px;
	height: 211px;
	background: url(../../../img/score/ui_game/sp_powerball_board_new.png) no-repeat 0 0
}

.cast_lots .bg_animation .ani1 {
	background-position: -211px 0
}

.cast_lots .bg_animation .ani2 {
	background-position: -422px 0
}

.cast_lots .bg_animation .ani3 {
	background-position: -633px 0
}

.cast_lots .bg_animation .ani4 {
	background-position: -844px 0
}

.cast_lots .bg_animation .ani5 {
	background-position: -1055px 0
}

.cast_lots .bg_animation .ani6 {
	background-position: -1266px 0
}

.cast_lots .bg_animation .ani7 {
	background-position: -1477px 0
}

.cast_lots .bg_animation .ani8 {
	background-position: -1688px 0
}

.cast_lots .bg_animation .ani9 {
	background-position: -1899px 0
}

.cast_lots .bg_animation.powerball span {
	background-position: 0 -211px
}

.cast_lots .bg_animation.powerball .ani1 {
	background-position: -211px -211px
}

.cast_lots .bg_animation.powerball .ani2 {
	background-position: -422px -211px
}

.cast_lots .bg_animation.powerball .ani3 {
	background-position: -633px -211px
}

.cast_lots .bg_animation.powerball .ani4 {
	background-position: -844px -211px
}

.cast_lots .bg_animation.powerball .ani5 {
	background-position: -1055px -211px
}

.cast_lots .bg_animation.powerball .ani6 {
	background-position: -1266px -211px
}

.cast_lots .bg_animation.powerball .ani7 {
	background-position: -1477px -211px
}

.cast_lots .bg_animation.powerball .ani8 {
	background-position: -1688px -211px
}

.cast_lots .bg_animation.powerball .ani9 {
	background-position: -1899px -211px
}

.waiting_score_board {
	position: absolute;
	top: 75px;
	left: -1px;
	width: 345px;
	height: 150px;
	z-index: 3;
	background: url(../../../img/score/ui_game/3min_powerball/waiting_kr.png) no-repeat 0 0;
	display: none;
}

.waiting_board {
	position: absolute;
	top: 75px;
	left: 3px;
	width: 345px;
	height: 150px;
	z-index: 3
}

.waiting_board:before {
	content: '';
	position: absolute;
	width: 345px;
	height: 150px;
	background: url(../../../img/score/ui_game/3min_powerball/loading/loading_BG1.png) no-repeat 0 0;
	z-index: 0
}

.waiting_board .inner {
	position: relative;
	margin: 13px 0 0 35px;
	width: 320px;
	height: 29px;
	border-radius: 50px;
	overflow: hidden
}

.waiting_board .gauge {
	position: absolute;
    left: -1px;
    width: 250px;
    height: 30px;
    animation: animate-stripes .5s linear infinite;
    background: #e0ecf100 url(../../../img/score/ui_game/3min_powerball/loading/time_bar.png);
    margin-left: 1px;
}

.waiting_board .gauge {
	transition: left .5s ease-in-out
}

.waiting_board .tx {
	position: relative;
	display: flex;
    height: 44px;
    margin: 2px 0 0 4px;
    line-height: 44px;
    text-align: left;
    width: 320px;
    z-index: 33;
}

.waiting_board .tx .date_round {
	width: 35%;
    text-align: center;
    color: #ffffff;
    font-family: "Nanum Gothic";
    font-weight: 800;
    font-size: 20px;
}

.waiting_board .tx .countdown_clock {
    width: 65%;
    color: #ffff00;
    font-family: "Nanum Gothic";
    font-weight: 800;
    font-size: 22px;
}

.waiting_board.msg .gauge {
	display: none
}

.score_board {
	position: absolute;
	top: 34px;
	left: -3px;
	/*padding: 17px 13px 13px;*/
	width: 316px;
	height: 230px;
	background: url(../../../img/score/ui_game/3min_powerball/result/result_BG.png) no-repeat 0 0
}

.score_board .curr_round {
	width: 100%;
    height: 44px;
    position: relative;
}

.curr_round .pick_num {
	position: absolute;
    top: 10px;
    left: 24px;
    font-family: Tahoma, Geneva, sans-serif;
    font-size: 18px;
    color: #dcdcdc;
}

.curr_round .pick_num2 {
	position: absolute;
    top: 10px;
    left: 225px;
    font-family: Tahoma, Geneva, sans-serif;
    font-size: 18px;
    color: #ffffff;
}
.score_board .result_info {
	float: left;
    margin-top: 40px;
    width: 319px;
    display: flex;
}
.result_info .normalball, .result_info .powerball {
	display: flex;
    justify-content: space-around;
    font-size: 18px;
    line-height: 40px;
    height: 40px;
	margin-right: 5px;
}
.result_info .normalball {
	width: calc(50% + 1px);
}
.result_info .powerball {
	margin-left: 1px;
	width: 50%;
}


.result_info .icon_even {background-position: -729px -1px !important}
.result_info .icon_odd {background-position: -729px -36px !important}
.result_info .icon_over {background-position: -729px -71px !important}
.result_info .icon_under {background-position: -764px -1px !important}
.result_info .powerball span, .result_info .normalball span {
	margin-left: 25px;
}
.result_info .powerball ul, .result_info .normalball ul {
	width: 70%;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}
.result_info .powerball li, .result_info .normalball li {
	width: 33px;
	height: 33px;
	background: url(../../../img/score/ui_game/3min_powerball/ball/balls.png) no-repeat -999px -999px;
}

.result_info .name {
	width: 40%;
	text-align: center;
	color: #000000;
	font-family: "Nanum Gothic";
	font-weight: 800;
	font-size: 18px;
}

.score_board .result_ball {
	float: left;
	width: 319px;
	height: 71px;
    padding: 13px 7px;
}

.score_board .result_ball em {
	float: left;
	margin-left: 1px
}

.score_board .result_ball em:first-child {
	margin-left: 0
}

.result_ball em {
	width:50px; 
	height:50px;
	background: url(../../../img/score/ui_game/3min_powerball/ball/ko_balls.png) no-repeat;
	text-indent: -9999px
}

.result_ball .n1 {background-position: -0px -0px}
.result_ball .n2 {background-position: -50px -0px}
.result_ball .n3 {background-position: -100px -0px}
.result_ball .n4 {background-position: -150px -0px}
.result_ball .n5 {background-position: -200px -0px}
.result_ball .n6 {background-position: -0px -50px}
.result_ball .n7 {background-position: -50px -50px}
.result_ball .n8 {background-position: -100px -50px}
.result_ball .n9 {background-position: -150px -50px}
.result_ball .n0 {background-position: -200px -50px}
.result_ball .n11 {background-position: -0px -100px}
.result_ball .n12 {background-position: -50px -100px}
.result_ball .n13 {background-position: -100px -100px}
.result_ball .n14 {background-position: -150px -100px}
.result_ball .n15 {background-position: -200px -100px}
.result_ball .n16 {background-position: -0px -150px}
.result_ball .n17 {background-position: -50px -150px}
.result_ball .n18 {background-position: -100px -150px}
.result_ball .n19 {background-position: -150px -150px}
.result_ball .n20 {background-position: -200px -150px}
.result_ball .n21 {background-position: -0px -200px}
.result_ball .n22 {background-position: -50px -200px}
.result_ball .n23 {background-position: -100px -200px}
.result_ball .n24 {background-position: -150px -200px}
.result_ball .n25 {background-position: -200px -200px}
.result_ball .n26 {background-position: -0px -250px}
.result_ball .n27 {background-position: -50px -250px}
.result_ball .n28 {background-position: -100px -250px}

.result_ball .p0 {background-position: -150px -250px}
.result_ball .p1 {background-position: -200px -250px}
.result_ball .p2 {background-position: -0px -300px}
.result_ball .p3 {background-position: -50px -300px}
.result_ball .p4 {background-position: -100px -300px}
.result_ball .p5 {background-position: -150px -300px}
.result_ball .p6 {background-position: -200px -300px}
.result_ball .p7 {background-position: -0px -350px}
.result_ball .p8 {background-position: -50px -350px}
.result_ball .p9 {background-position: -100px -350px}

.po_box_right_new .n1 {background-position: -0px -433px}
.po_box_right_new .n2 {background-position: -33px -433px}
.po_box_right_new .n3 {background-position: -66px -433px}
.po_box_right_new .n4 {background-position: -99px -433px}
.po_box_right_new .n5 {background-position: -132px -433px}
.po_box_right_new .n6 {background-position: -165px -433px}
.po_box_right_new .n7 {background-position: -198px -433px}
.po_box_right_new .n8 {background-position: -0px -466px}
.po_box_right_new .n9 {background-position: -33px -466px}
.po_box_right_new .n10 {background-position: -66px -466px}
.po_box_right_new .n11 {background-position: -99px -466px}
.po_box_right_new .n12 {background-position: -132px -466px}
.po_box_right_new .n13 {background-position: -165px -466px}
.po_box_right_new .n14 {background-position: -198px -466px}
.po_box_right_new .n15 {background-position: -0px -499px}
.po_box_right_new .n16 {background-position: -33px -499px}
.po_box_right_new .n17 {background-position: -66px -499px}
.po_box_right_new .n18 {background-position: -99px -499px}
.po_box_right_new .n19 {background-position: -132px -499px}
.po_box_right_new .n20 {background-position: -165px -499px}
.po_box_right_new .n21 {background-position: -198px -499px}
.po_box_right_new .n22 {background-position: -0px -532px}
.po_box_right_new .n23 {background-position: -33px -532px}
.po_box_right_new .n24 {background-position: -66px -532px}
.po_box_right_new .n25 {background-position: -99px -532px}
.po_box_right_new .n26 {background-position: -132px -532px}
.po_box_right_new .n27 {background-position: -165px -532px}
.po_box_right_new .n28 {background-position: -198px -532px}

.po_box_right_new .p0 {background-position: -150px -350px}
.po_box_right_new .p1 {background-position: -183px -350px}
.po_box_right_new .p2 {background-position: -216px -350px}
.po_box_right_new .p3 {background-position: -0px -400px}
.po_box_right_new .p4 {background-position: -33px -400px}
.po_box_right_new .p5 {background-position: -66px -400px}
.po_box_right_new .p6 {background-position: -99px -400px}
.po_box_right_new .p7 {background-position: -132px -400px}
.po_box_right_new .p8 {background-position: -165px -400px}
.po_box_right_new .p9 {background-position: -198px -400px}

.po_box_left_new .icon_even {background-position: -729px -1px}
.po_box_left_new .icon_odd {background-position: -729px -36px}
.po_box_left_new .icon_over {background-position: -729px -71px}
.po_box_left_new .icon_under {background-position: -764px -1px}
.po_box_left_new .vl {border-left: 1px solid #1673c2; height: 33px;}
.po_box_right_new .vl {/*border-left: 2px solid #1673c2;*/ height: 33px; width: 2px; background:none;}

.result_board {
	position: absolute;
	top: 91px;
	left: 46px;
	padding: 26px 27px 0;
	width: 275px;
	height: 105px;
	line-height: 15px;
	background: url(../../../img/score/ui_game/sp_powerball_new.png) no-repeat 0 -400px;
	color: #fae6c5
}

.result_board dt {
	clear: both;
	float: left;
	margin-bottom: 6px;
	font-weight: 700
}

.result_board dd {
	float: right;
	margin-bottom: 5px
}

.po_box_wrap_left_header {
	position: absolute;
    width: 250px;
    height: 40px;
    top: 119px;
    left: 15px;
	display: flex;
	flex-direction: row;
}
.po_box_wrap_left_header.left-active {
	background: url("../../../img/score/ui_game/3min_powerball/info_icon/left_button_on.png") no-repeat 0 0;
}
.po_box_wrap_left_header.right-active {
	background: url("../../../img/score/ui_game/3min_powerball/info_icon/left_button_off.png") no-repeat 0 0;
}
.po_box_wrap_left_header .left {
	width: 50%;
	height: 100%;
	cursor: pointer;
}
.po_box_wrap_left_header .right {
	width: 50%;
	height: 100%;
	cursor: pointer;
}


.po_box_wrap_right_header {
	position: absolute;
    width: 250px;
    height: 40px;
    top: 119px;
    right: 15px;
	display: flex;
	flex-direction: row;
}
.po_box_wrap_right_header.left-active {
	background: url("../../../img/score/ui_game/3min_powerball/info_icon/button_on.png") no-repeat 0 0;
}
.po_box_wrap_right_header.right-active {
	background: url("../../../img/score/ui_game/3min_powerball/info_icon/button_off.png") no-repeat 0 0;
}
.po_box_wrap_right_header .left {
	width: 50%;
	height: 100%;
	cursor: pointer;
}
.po_box_wrap_right_header .right {
	width: 50%;
	height: 100%;
	cursor: pointer;
}
.po_box_wrap_right_new {
	position: absolute;
    width: 242px;
    height: 450px;
    top: 165px;
    right: 22px;
    overflow-y: scroll;
}
.po_box_wrap_left_new {
	position: absolute;
	direction: rtl;
    width: 243px;
    height: 415px;
    top: 200px;
    left: 25px;
    overflow-y: scroll;
}
.ai_title{
	z-index: 9;
	position: relative;
	top: 167px;
	right: -57px;
	font-size: 18px;
	color: #FFFFFF;
	font-weight: bold;
	font-family: "Nanum Gothic";
}

.po_box_right_new {
	float: left;
    width: 220px;
    height: 90px;
    background: url(../../../img/score/ui_game/3min_powerball/card.png) center top no-repeat;
    text-align: center;
    padding: 8px 11px 0;
    font-size: 12px;
    font-family: "Nanum Gothic";
    letter-spacing: 0px;
    color: #b8c6e5;
    font-weight: 700;
    margin: 6px 4px 0 6px;
}
.po_box_right_new ul li {
	display: inline-block;
	margin: 3px;
}
.po_box_right_new li {
	width: 33px;
	height: 33px;
	background: url(../../../img/score/ui_game/3min_powerball/ball/normal.png) no-repeat 0 0;
	color: black;
    font-size: 18px;
    font-weight: bolder;
    line-height: 33px;
}
.po_box_right_new li.power-ball {
	background: url(../../../img/score/ui_game/3min_powerball/ball/yellow.png) no-repeat 0 0;
	margin : -1px;
}
.po_box_right_new li.ball {
	margin : -1px;
}
.po_box_right_new ul {
	margin-top: 24px;
	margin-left: 3px;
}

.po_box_right_new ul.first_tab {
	margin-top: 14px;
}

.po_box_left_new {
	position: relative;
	float: right;
    direction: ltr;
    width: 210px;
    height: 60px;
    text-align: center;
    background-color: #462611;
    padding: 8px 11px;
    font-size: 12px;
    font-family: "Nanum Gothic";
    letter-spacing: 0px;
    color: #b8c6e5;
    font-weight: 700;
    margin: 6px 15px 0px 6px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.po_box_left_new .number {
	position: absolute;
	left: 34px;
	bottom: 2px;
	width: 20px;
	height: 20px;
	background-color: red;
	color: white;
	line-height: 18px;
	font-size: 14px;
}

.last_result{
	position: absolute;
	left: 65px;
	top: 5px;
	float: left;
    width: 44px;
    height: 44px;
	background: url(../../../img/score/ui_game/peices/circle.png) no-repeat 0 0;
}

.po_box_left_new .small {
	position:absolute;
	right : -10px;
	bottom : 0px;
	width: 19px;
	height : 19px;
	background: url(../../../img/score/ui_game/peices/small.png) no-repeat 0 0;
}

.po_box_left_new .middle {
	position:absolute;
	right : -10px;
	bottom : 13px;
	width: 19px;
	height : 19px;
	background: url(../../../img/score/ui_game/peices/middle.png) no-repeat 0 0;
}

.po_box_left_new .big {
	position:absolute;
	right : -10px;
	top : 0px;
	width: 19px;
	height : 19px;
	background: url(../../../img/score/ui_game/peices/big.png) no-repeat 0 0;
}


.rtop {
	position: absolute;
	right :0px;
	top: 0px;
	width: 22px;
    height: 22px;
	margin: -1px;
}

.po_box_left_new .oe-odd {
	background: url(../../../img/score/ui_game/peices/odd_top_right.png) no-repeat 0 0;
}

.po_box_left_new .oe-even {
	background: url(../../../img/score/ui_game/peices/even_top_right.png) no-repeat 0 0;
}
.ltop {
	position: absolute;
	left :0px;
	top: 0px;
	width: 22px;
    height: 22px;
	margin: -1px;
}

.po_box_left_new .poe-odd {
	background: url(../../../img/score/ui_game/peices/odd_top_left.png) no-repeat 0 0;
}

.po_box_left_new .poe-even {
	background: url(../../../img/score/ui_game/peices/even_top_left.png) no-repeat 0 0;
}

.rbottom {
	position: absolute;
	right :0px;
	bottom: 0px;
	width: 22px;
    height: 22px;
	margin: -1px;
}

.po_box_left_new .ou-over {
	background: url(../../../img/score/ui_game/peices/over_bottom_right.png) no-repeat 0 0;
}

.po_box_left_new .ou-under {
	background: url(../../../img/score/ui_game/peices/under_bottom_right.png) no-repeat 0 0;
}

.lbottom {
	position: absolute;
	left :0px;
	bottom: 0px;
	width: 22px;
    height: 22px;
	margin: -1px;
}

.po_box_left_new .pou-over {
	background: url(../../../img/score/ui_game/peices/over_bottom_left.png) no-repeat 0 0;
}

.po_box_left_new .pou-under {
	background: url(../../../img/score/ui_game/peices/under_bottom_left.png) no-repeat 0 0;
}

.new_pick_grey{
	opacity: 0.5;
}

.pickster_detail{
	color: black;
	position: absolute;
    right: 3px;
	font-size: 10px;
	color: white;
}

.new_pick{
	position: absolute;
	left: 115px;
	top: 5px;
	float: left;
    width: 44px;
    height: 44px;
	background: url(../../../img/score/ui_game/peices/circle.png) no-repeat 0 0;
}

.new_pick_pass{
	width: 44px;
    height: 44px;
	background: url(../../../img/score/ui_game/peices/pass.png) no-repeat 0 0;
}

.po_box_left_new .lostNumber {
	position: absolute;
	left: -5px;
	top: -5px;
	width: 20px;
	height: 20px;
	background-color: blue;
	border: 2px solid black;
	color: white;
	line-height: 18px;
	font-size: 14px;
	border-radius: 5px;
}

.avatar {
	width: 52.5px;
	height: 55.5px;
	background-size: cover;
	display: inline-block;
	margin-left: -10px;
}
.avatar1 {background: url(../../../img/score/ui_game/avatars/1.gif) no-repeat 0 0;}
.avatar2 {background: url(../../../img/score/ui_game/avatars/2.gif) no-repeat 0 0;}
.avatar3 {background: url(../../../img/score/ui_game/avatars/3.gif) no-repeat 0 0;}
.avatar4 {background: url(../../../img/score/ui_game/avatars/4.gif) no-repeat 0 0;}
.avatar5 {background: url(../../../img/score/ui_game/avatars/5.gif) no-repeat 0 0;}
.avatar6 {background: url(../../../img/score/ui_game/avatars/6.gif) no-repeat 0 0;}
.avatar7 {background: url(../../../img/score/ui_game/avatars/7.gif) no-repeat 0 0;}
.avatar8 {background: url(../../../img/score/ui_game/avatars/8.gif) no-repeat 0 0;}
.avatar9 {background: url(../../../img/score/ui_game/avatars/9.gif) no-repeat 0 0;}
.avatar10 {background: url(../../../img/score/ui_game/avatars/10.gif) no-repeat 0 0;}
.avatar11 {background: url(../../../img/score/ui_game/avatars/11.gif) no-repeat 0 0;}
.avatar12 {background: url(../../../img/score/ui_game/avatars/12.gif) no-repeat 0 0;}
.avatar13 {background: url(../../../img/score/ui_game/avatars/13.gif) no-repeat 0 0;}
.avatar14 {background: url(../../../img/score/ui_game/avatars/14.gif) no-repeat 0 0;}
.avatar15 {background: url(../../../img/score/ui_game/avatars/15.gif) no-repeat 0 0;}
.avatar16 {background: url(../../../img/score/ui_game/avatars/16.gif) no-repeat 0 0;}
.avatar17 {background: url(../../../img/score/ui_game/avatars/17.gif) no-repeat 0 0;}
.avatar18 {background: url(../../../img/score/ui_game/avatars/18.gif) no-repeat 0 0;}
.avatar19 {background: url(../../../img/score/ui_game/avatars/19.gif) no-repeat 0 0;}
.avatar20 {background: url(../../../img/score/ui_game/avatars/20.gif) no-repeat 0 0;}
.avatar21 {background: url(../../../img/score/ui_game/avatars/21.gif) no-repeat 0 0;}
.avatar22 {background: url(../../../img/score/ui_game/avatars/22.gif) no-repeat 0 0;}
.avatar23 {background: url(../../../img/score/ui_game/avatars/23.gif) no-repeat 0 0;}
.avatar24 {background: url(../../../img/score/ui_game/avatars/24.gif) no-repeat 0 0;}
.avatar25 {background: url(../../../img/score/ui_game/avatars/25.gif) no-repeat 0 0;}
.avatar26 {background: url(../../../img/score/ui_game/avatars/26.gif) no-repeat 0 0;}
.avatar27 {background: url(../../../img/score/ui_game/avatars/27.gif) no-repeat 0 0;}
.avatar28 {background: url(../../../img/score/ui_game/avatars/28.gif) no-repeat 0 0;}
.avatar29 {background: url(../../../img/score/ui_game/avatars/29.gif) no-repeat 0 0;}
.avatar30 {background: url(../../../img/score/ui_game/avatars/30.gif) no-repeat 0 0;}
.avatar31 {background: url(../../../img/score/ui_game/avatars/31.gif) no-repeat 0 0;}
.avatar32 {background: url(../../../img/score/ui_game/avatars/32.gif) no-repeat 0 0;}
.avatar33 {background: url(../../../img/score/ui_game/avatars/33.gif) no-repeat 0 0;}
.avatar34 {background: url(../../../img/score/ui_game/avatars/34.gif) no-repeat 0 0;}
.avatar35 {background: url(../../../img/score/ui_game/avatars/35.gif) no-repeat 0 0;}
.avatar36 {background: url(../../../img/score/ui_game/avatars/36.gif) no-repeat 0 0;}
.avatar37 {background: url(../../../img/score/ui_game/avatars/37.gif) no-repeat 0 0;}
.avatar38 {background: url(../../../img/score/ui_game/avatars/38.gif) no-repeat 0 0;}
.avatar39 {background: url(../../../img/score/ui_game/avatars/39.gif) no-repeat 0 0;}
.avatar40 {background: url(../../../img/score/ui_game/avatars/40.gif) no-repeat 0 0;}
.avatar41 {background: url(../../../img/score/ui_game/avatars/41.gif) no-repeat 0 0;}
.avatar42 {background: url(../../../img/score/ui_game/avatars/42.gif) no-repeat 0 0;}
.avatar43 {background: url(../../../img/score/ui_game/avatars/43.gif) no-repeat 0 0;}
.avatar44 {background: url(../../../img/score/ui_game/avatars/44.gif) no-repeat 0 0;}
.avatar45 {background: url(../../../img/score/ui_game/avatars/45.gif) no-repeat 0 0;}
.avatar46 {background: url(../../../img/score/ui_game/avatars/46.gif) no-repeat 0 0;}
.avatar47 {background: url(../../../img/score/ui_game/avatars/47.gif) no-repeat 0 0;}
.avatar48 {background: url(../../../img/score/ui_game/avatars/48.gif) no-repeat 0 0;}
.avatar0 {background: url(../../../img/score/ui_game/avatars/49.gif) no-repeat 0 0;}

.po_box_left_new .name {
	position: absolute;
	left: 60px;
	color: white;
	font-size: 14px;
}
.po_box_left_new .under {
	background: url("../../../img/score/ui_game/3min_powerball/info_icon/icon_group.png") no-repeat 0 5px;
	background-position: -35px 0px;
	width: 35px;
	height: 40px;
	display: inline-block;
}
.po_box_right_new .under {
	background: url("../../../img/score/ui_game/3min_powerball/info_icon/icon_group.png") no-repeat 0 5px;
	background-position: -35px 0px;
	width: 35px;
	height: 40px;
	display: inline-block;
}
.po_box_left_new .under_p {
	background: url("../../../img/score/ui_game/3min_powerball/info_icon/icon_group.png") no-repeat 0 5px;
	background-position: 0px 0px;
	width: 35px;
	height: 40px;
	display: inline-block;
}

.po_box_right_new .under_p {
	background: url("../../../img/score/ui_game/3min_powerball/info_icon/icon_group.png") no-repeat 0 5px;
	background-position: 0px 0px;
	width: 35px;
	height: 40px;
	display: inline-block;
}
.po_box_left_new .over {
	background: url("../../../img/score/ui_game/3min_powerball/info_icon/icon_group.png") no-repeat 0 5px;
	background-position: -106px -40px;
	width: 35px;
	height: 40px;
	display: inline-block;
}

.po_box_right_new .over {
	background: url("../../../img/score/ui_game/3min_powerball/info_icon/icon_group.png") no-repeat 0 5px;
	background-position: -106px -40px;
	width: 35px;
	height: 40px;
	display: inline-block;
}

.po_box_right_new .odd {
	background: url("../../../img/score/ui_game/3min_powerball/info_icon/icon_group.png") no-repeat 0 5px;
	background-position: -106px 0px;
	width: 35px;
	height: 40px;
	display: inline-block;
}

.po_box_left_new .odd {
	background: url("../../../img/score/ui_game/3min_powerball/info_icon/icon_group.png") no-repeat 0 5px;
	background-position: -106px 0px;
	width: 35px;
	height: 40px;
	display: inline-block;
}

.po_box_left_new .even {
	background: url("../../../img/score/ui_game/3min_powerball/info_icon/icon_group.png") no-repeat 0 5px;
	background-position: -35px -40px;
	width: 35px;
	height: 40px;
	display: inline-block;
}

.po_box_left_new .even_p {
	background: url("../../../img/score/ui_game/3min_powerball/info_icon/icon_group.png") no-repeat 0 5px;
	background-position: 0px -40px;
	width: 35px;
	height: 40px;
	display: inline-block;
}

.po_box_right_new .even_p {
	background: url("../../../img/score/ui_game/3min_powerball/info_icon/icon_group.png") no-repeat 0 5px;
	background-position: 0px -40px;
	width: 35px;
	height: 40px;
	display: inline-block;
}

.po_box_left_new .odd_p {
	background: url("../../../img/score/ui_game/3min_powerball/info_icon/icon_group.png") no-repeat 0 5px;
	background-position: -70px 0px;
	width: 35px;
	height: 40px;
	display: inline-block;
}

.po_box_right_new .odd_p {
	background: url("../../../img/score/ui_game/3min_powerball/info_icon/icon_group.png") no-repeat 0 5px;
	background-position: -70px 0px;
	width: 35px;
	height: 40px;
	display: inline-block;
}

.po_box_right_new .even {
	background: url("../../../img/score/ui_game/3min_powerball/info_icon/icon_group.png") no-repeat 0 5px;
	background-position: -35px -40px;
	width: 36px;
	height: 40px;
	display: inline-block;
}

.po_box_left_new .over_p {
	background: url("../../../img/score/ui_game/3min_powerball/info_icon/icon_group.png") no-repeat 0 5px;
	background-position: -70px -40px;
	width: 36px;
	height: 40px;
	display: inline-block;
}

.po_box_right_new .over_p {
	background: url("../../../img/score/ui_game/3min_powerball/info_icon/icon_group.png") no-repeat 0 5px;
	background-position: -70px -40px;
	width: 36px;
	height: 40px;
	display: inline-block;
}
.po_box_left_new .m_icon_on {
	background: url("../../../img/score/ui_game/3min_powerball/info_icon/icon_group.png") no-repeat 0 5px;
	background-position: 0px -80px;
	width: 30px;
	height: 40px;
	display: inline-block;
}
.po_box_left_new .m_icon_off {
	background: url("../../../img/score/ui_game/3min_powerball/info_icon/icon_group.png") no-repeat 0 5px;
	background-position: -38px -80px;
	width: 30px;
	height: 40px;
	display: inline-block;
}
.po_box_left_new .lock {
	background: url("../../../img/score/ui_game/3min_powerball/info_icon/icon_group.png") no-repeat 0 5px;
	background-position: -70px -80px;
	width: 30px;
	height: 40px;
	display: inline-block;
}
.po_box_left_new ul li {
	display: inline-block;
	margin: 0;
}
.po_box_left_new li {
	width: 33px;
	height: 33px;
	background: url(../../../img/score/ui_game/3min_powerball/ball/balls.png) no-repeat 0 0;
}
.po_box_left_new ul {
	display: flex;
    justify-content: space-around;
    height: 100%;
    align-items: center;
}

.pattern_graph {
    width: 880px;
    #margin-left: 150px;
}

.pattern_graph .main_tab {
    display: table;
    border: 1px solid #d6d6d6;
}
.pattern_graph .main_tab ul:after {
    display: block;
    clear: both;
    content: ''}
.pattern_graph .main_tab li {
    display: table-cell;
    width: 500px;
    border-left: 1px solid #e6e6ea;
    text-align: center;
}
.pattern_graph .main_tab li:first-child {
    border-left: none;
}
.pattern_graph .main_tab a {
    position: relative;
    display: block;
    height: 51px;
    line-height: 51px;
    background: #f2f2f7;
}
.pattern_graph .main_tab .selected {
    margin: -1px;
    border: 1px solid #d6d6d6;
    height: 52px;
    border-bottom: none;
    background-color: #fff;
}
.pattern_graph .sub_tab {
    padding: 15px 0 0 20px;
    height: 36px;
    border-left: 1px solid #d6d6d6;
    border-right: 1px solid #d6d6d6;
}
.pattern_graph .sub_tab li {
    float: left;
    margin-right: 30px;
}

.pattern_graph .sub_tab .selected {
    background-position: 0 -60px;
}
.pattern_graph .view .info {
    padding-top: 12px;
    height: 39px;
    border-left: 1px solid #d6d6d6;
    border-right: 1px solid #d6d6d6;
}
.pattern_graph .view .info li {
    float: left;
    line-height: 27px;
}
.pattern_graph .view .info .ic {
    float: left;
    margin: 0 10px 0 20px;
    width: 27px;
    height: 27px;
    text-align: center;
    border-radius: 16px;
    color: #fff;
}
.pattern_graph .view .info .tx {
    float: left;
}
.pattern_graph .view .info .left .ic, .pattern_graph .view .info .line3 .ic, .pattern_graph .view .info .odd .ic, .pattern_graph .view .info .under .ic {
    background-color: #3498db;
}
.pattern_graph .view .info .right .ic, .pattern_graph .view .info .line4 .ic, .pattern_graph .view .info .even .ic, .pattern_graph .view .info .over .ic {
    background-color: #e74c3c;
}
.pattern_graph .view .info .left4odd .ic {
    background-color: #3498db;
}
.pattern_graph .view .info .right3odd .ic {
    background-color: #2980b9;
}
.pattern_graph .view .info .left3even .ic {
    background-color: #e74c3c;
}
.pattern_graph .view .info .right4even .ic {
    background-color: #c0392b;
}
.pattern_graph .view .info .x .ic {
    background-color: #7b7b7b;
}
.pattern_graph .view .info .etc {
    float: right;
    padding-right: 20px;
}
.pattern_graph .view .box_area {
    position: relative;
    margin-top: -1px;
    width: auto;
    border: 1px solid #d6d6d6;
}
.pattern_graph .view .inner {
    padding-bottom: 34px;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0;
    background: url(../../../img/score/ui_game/bg_cell.png) repeat;
}
.pattern_graph .view:after {
    display: block;
    clear: both;
    content: ''}
.pattern_graph .view dl {
    display: inline-block;
    vertical-align: top;
}
.pattern_graph .view dl .length {
    position: absolute;
    bottom: 34px;
    background: url(../../../img/score/ui_game/bg_cell_length.png) repeat;
}
.pattern_graph .view dl .times {
    position: absolute;
    bottom: 0;
    background: url(../../../img/score/ui_game/bg_cell_times.png) repeat;
}
.pattern_graph .view dt, .pattern_graph .view dd {
    width: 34px;
    height: 34px;
    line-height: 33px;
    padding: 0 1px 1px 0;
    text-align: center;
    font-size: 12px;
}
.pattern_graph .view dt {
    position: relative;
    z-index: 1;
    background: url(../../../img/score/ui_game/bg_cell_title.png) repeat;
}
.pattern_graph .view dt.ODD, .pattern_graph .view dt.UNDER, .pattern_graph .view dt.LEFT, .pattern_graph .view dt._3, .pattern_graph .view dt.LEFT4ODD, .pattern_graph .view dt.RIGHT3ODD {
    color: #2980b9;
}
.pattern_graph .view dt.EVEN, .pattern_graph .view dt.OVER, .pattern_graph .view dt.RIGHT, .pattern_graph .view dt._4, .pattern_graph .view dt.LEFT3EVEN, .pattern_graph .view dt.RIGHT4EVEN {
    color: #c0392b;
}
.pattern_graph .view dd span {
    position: relative;
    display: inline-block;
    margin-top: 3px;
    width: 27px;
    height: 27px;
    line-height: 27px;
    border-radius: 16px;
    font-size: 9px;
    color: #fff;
}
.pattern_graph .view dd span.odd, .pattern_graph .view dd span.ODD, .pattern_graph .view dd span.under, .pattern_graph .view dd span.UNDER, .pattern_graph .view dd span.LEFT, .pattern_graph .view dd span._3, .pattern_graph .view dd span.LEFT4ODD, .pattern_graph .view dd span.RIGHT3ODD {
    background-color: #3498db;
}
.pattern_graph .view dd span.RIGHT3ODD {
    background-color: #2980b9;
}
.pattern_graph .view dd span.even, .pattern_graph .view dd span.EVEN, .pattern_graph .view dd span.over, .pattern_graph .view dd span.OVER, .pattern_graph .view dd span.RIGHT, .pattern_graph .view dd span._4, .pattern_graph .view dd span.LEFT3EVEN, .pattern_graph .view dd span.RIGHT4EVEN {
    background-color: #e74c3c;
    border-radius: 16px;
}
.pattern_graph .view dd span.RIGHT4EVEN {
    background-color: #c0392b;
}
.pattern_graph .view dd span.X {
    background-color: #7b7b7b;
    border-radius: 16px;
}
.pattern_graph .view dd span.snail1, .pattern_graph .view .info .snail1 .ic {
    background-color: #3498db;
}
.pattern_graph .view dd span.snail2, .pattern_graph .view .info .snail2 .ic {
    background-color: #e74c3c;
}
.pattern_graph .view dd span.snail3, .pattern_graph .view .info .snail3 .ic {
    background-color: #2980b9;
}
.pattern_graph .view dd span.snail4, .pattern_graph .view .info .snail4 .ic {
    background-color: #c0392b;
}
.pattern_graph .view .dot {
    position: absolute;
    width: 9px;
    height: 9px;
    background: url(../../../img/score/ui_game/sp_stats.png) no-repeat;
}
.pattern_graph .view .dot.LEFT4ODD {
    left: 0;
    background-position: -260px -30px;
}
.pattern_graph .view .dot.RIGHT3ODD {
    right: 0;
    background-position: -240px -30px;
}
.pattern_graph .view .dot.LEFT3EVEN {
    left: 0;
    background-position: -240px -30px;
}
.pattern_graph .view .dot.RIGHT4EVEN {
    right: 0;
    background-position: -260px -30px;
}
.basic_pattern_graph .main_tab {
    margin-bottom: -1px;
    border: 1px solid #727272;
}
.basic_pattern_graph .main_tab .selected {
    border: 1px solid #606060;
    border-bottom: none;
    background-color: #fff;
    color: #333;
}
.basic_pattern_graph .main_tab li {
    border-left: 1px solid #7b7b7b;
}
.basic_pattern_graph .main_tab li:first-child {
    border-left: none;
}
.basic_pattern_graph .main_tab a {
    background-color: #7b7b7b;
    color: #fff;
}
.basic_pattern_graph .main_tab a:after {
    content: '';
    position: absolute;
    top: 20px;
    left: -1px;
    width: 1px;
    height: 11px;
    background-color: #6e6e6e;
    z-index: 1;
}
.basic_pattern_graph .main_tab li:first-child a:after {
    display: none;
}
.basic_pattern_graph .main_tab .selected {
    z-index: 1;
}
.basic_pattern_graph .main_tab .selected:after {
    display: none;
}
.basic_pattern_graph .view .inner {
    padding-bottom: 68px;
}
.basic_pattern_graph .view .info {
    border-left: 1px solid #727272;
    border-right: 1px solid #727272;
    border-top: 1px solid #727272;
}
.basic_pattern_graph .view .box_area {
    border: 1px solid #727272;
    border-top: 1px solid #d6d6d6;
}

/*powerball_stats_list*/
.powerball_stats_list .tbl {
	width: 890px;
	border: 1px solid #d6d6d6;
	margin-top: 50px;
}

.powerball_stats_list caption {
	display: none
}

.powerball_stats_list th {
	height: 51px;
	border-bottom: 1px solid #d6d6d6;
	background: #f2f2f7
}

.powerball_stats_list td {
	height: 51px;
	text-align: center;
	border-bottom: 1px solid #f2f2f2
}

.powerball_stats_list thead {
	border-bottom: 1px solid #d6d6d6
}

.powerball_stats_list thead tr:first-child th {
	border-top: none
}

.powerball_stats_list tr:first-child td {
	border-top: 1px solid #d6d6d6
}

.powerball_stats_list th:first-child,
.powerball_stats_list td:first-child {
	border-left: none
}

.powerball_stats_list th.line_left,
.powerball_stats_list td.line_left {
	border-left: 1px solid #d6d6d6
}

.powerball_stats_list th {
	border-left: 1px solid #d6d6d6;
	border-top: 1px solid #d6d6d6;
	border-bottom: none
}

.powerball_stats_list td {
	border-left: 1px solid #d6d6d6;
	border-top: 1px solid #d6d6d6;
	border-bottom: none
}

.powerball_stats_list .oddeven_cell span {
	display: inline-block;
	width: 31px;
	height: 31px;
	line-height: 31px;
	border-radius: 16px;
	color: #fff
}

.powerball_stats_list .oddeven_cell .ODD {
	background-color: #3498db
}

.powerball_stats_list .oddeven_cell .EVEN {
	background-color: #e74c3c
}

.powerball_stats_list .unover_cell span {
	display: inline-block;
	width: 29px;
	height: 29px;
	line-height: 29px;
	border-radius: 16px
}

.powerball_stats_list .unover_cell .UNDER {
	border: 1px solid #3498db;
	color: #3498db
}

.powerball_stats_list .unover_cell .OVER {
	border: 1px solid #e74c3c;
	color: #e74c3c
}

.powerball_stats_list .unover_cell .X {
	border: 1px solid #7b7b7b;
	color: #7b7b7b
}

.powerball_stats_list .border_cell span {
	display: inline-block;
	width: 29px;
	height: 29px;
	line-height: 29px;
	border-radius: 16px;
	border: 1px solid #d8d8d8
}

.powerball_stats_list .border_cell .BIG {
	border: 1px solid #e74c3c;
	color: #e74c3c
}

.powerball_stats_list .border_cell .MIDDLE {
	border: 1px solid #3498db;
	color: #3498db
}

.powerball_stats_list .border_cell .SMALL {
	border: 1px solid #27ae60;
	color: #27ae60
}

.powerball_stats_list .many {
	font-weight: 700
}

.powerball_stats_list .odd.many {
	color: #3498db
}

.powerball_stats_list .even.many {
	color: #e74c3c
}

.powerball_stats_list .under.many {
	color: #3498db
}

.powerball_stats_list .over.many {
	color: #e74c3c
}

.powerball_stats_list .big.many {
	color: #e74c3c
}

.powerball_stats_list .middle.many {
	color: #3498db
}

.powerball_stats_list .small.many {
	color: #27ae60
}

.btn_list_more {
	margin-top: -1px;
	width: 890px;
	height: 43px;
	line-height: 41px;
	text-align: center;
	border: 1px solid #d6d6d6;
	background-color: #f2f2f7;
    cursor: pointer;
}

.loading-box {
	text-align: center;
    width: 890px;
}

.po_box_wrap_right_new::-webkit-scrollbar {
  width: 12px;
}

/* Handle */
.po_box_wrap_right_new::-webkit-scrollbar-thumb {
  background: #272012; 
  border-radius: 5px;
}

/* Handle on hover */
.po_box_wrap_right_new::-webkit-scrollbar-thumb:hover {
  background: #685e49; 
}

.po_box_wrap_left_new::-webkit-scrollbar {
  width: 12px;
}

/* Handle */
.po_box_wrap_left_new::-webkit-scrollbar-thumb {
  background: #272012; 
  border-radius: 5px;
}

/* Handle on hover */
.po_box_wrap_left_new::-webkit-scrollbar-thumb:hover {
  background: #685e49; 
}

audio, embed {
    position: absolute;
    z-index: -9999;
}


#pickinfo {
	z-index: 5000;
	width: 176px;
	height: 102px;
	background-color: #3d2b21;
	border-radius: 5px;
	font-family:"Nanum Gothic";
	overflow: hidden;
	border: 1px solid #1c1c1c;
}

#pickinfo h2 {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	height: 34px;
	background-color: #2b1e16;
	color: #fff
}

#pickinfo ul {
	padding-top: 3px
}

#pickinfo ul li {
	line-height: 20px;
	display: flex;
	align-items: center;
	justify-content: left;
	color: #99856c
}

#pickinfo ul li span {
	display: flex;
	font-size: 11px
}

#pickinfo ul li span.col {
	width: 60px;
	justify-content: center;
	font-weight: 700;
	color: #cbbfb1
}

.red {
	color: #e62626
}

.blue {
	color: #1ba9de
}

.po_top_home {
	z-index: 10;
	position: absolute;
	top: 15px;
	left: 20px;
}
.po_top_home div {
	margin: 15px 0 0 5px;
}



.help_icon {
	background: url("../../../img/score/ui_game/3min_powerball/info_icon/question_icon.png") no-repeat 0 0;
	width: 40px;
	height: 40px;
	display: inline-block;
}
.help_icon:active {
	background: url("../../../img/score/ui_game/3min_powerball/info_icon/question_down.png") no-repeat 0 0;
}
.home_icon {
	background: url("../../../img/score/ui_game/3min_powerball/info_icon/home_icon.png") no-repeat 0 0;
	width: 40px;
	height: 40px;
	display: inline-block;
}
.home_icon:active {
	background: url("../../../img/score/ui_game/3min_powerball/info_icon/home_down.png") no-repeat 0 0;
}
.sound_active {
	background: url("../../../img/score/ui_game/3min_powerball/info_icon/sound_on.png") no-repeat 0 0;
	width: 40px;
	height: 40px;
	display: inline-block;
}
.sound_inactive {
	background: url("../../../img/score/ui_game/3min_powerball/info_icon/sound_off.png") no-repeat 0 0;
	width: 40px;
	height: 40px;
	display: inline-block;
}



.po_top_loginbox {
	z-index: 10;
	position: absolute;
	top: 15px;
	right: 20px;
}

  .my-form{
	padding: 5px 5px;
	border-radius: 10px;
  }
  .form-title{
	text-align: center;
	margin-bottom: 30px;
  }
  .form-title h1{
	margin: 0px;
	color: #0cd6a8;
  }
  .single-input{
	width: 180px;
	display: flex;
	margin-bottom: 5px;
  }
  .single-input i{
	background-color: white;
	padding: 7px 9px;
	color: #6c6e78;
  }
  .single-input input{
	border:0px solid #c1c1c1;
	width: 100%;
	outline: none;
	height: 26px;
	font-size: 12px;
  }
  .submit-btn{
	 border:0px solid #c1c1c1;
	 margin-top: 5px;
  }
  .submit-btn button{
	 color: #fff;
	 cursor: pointer; 
  }
  .single-input button{
	border:0px solid #c1c1c1;
	width: 100%;
	outline: none;
	height: 25px;
	font-size: 12px;
  }

.single-input .login{
	background: #2ad3f9;
	margin-right: 2px;
}

.single-input .signup{
	margin-left: 2px;
	background: #db6263;
}


.manualList {
	margin: auto;
	background: url("../../../img/score/ui_game/game_info.png") no-repeat 0 0;
	width: 858px;
	height: 500px;
	position: absolute;
	top: 147px;
	left: 17px;
	color: #FFFFFF;
	font-family: "Nanum Gothic";
	margin-top: 0px;
	z-index: 100;
	border-radius: 12px;
	overflow-x: auto;
	background-color: rgba(0, 0, 0, 1);
}

.manualList .help_title {
	color: #FFFFFF;
	font-family: "Nanum Gothic";
	font-size: 25px;
	font-weight: bold;
	margin: 35px 30px 50px 35px;
	vertical-align: middle;
	height: 50px;
}

.manualList .help_content{
	margin: 35px 30px 50px 35px;
}
.manualList .btn_close {
	background:url(../../../img/score/ui_game/3min_powerball/btn_close.png) no-repeat 0 0;
	width: 90px;
	height: 90px;
	position: absolute;
	left: 760px;
	top: 5px;
	cursor: pointer;
}
.manualList .sub_title{
	font-size: 17px;
	color: #970000;
	font-weight: bold;
}

.manualList dd{
	color: black;
	font-weight: bold;
	font-size: 15px;
	margin-top: 2px;
}
.nick {
	vertical-align: middle;
	top: -10px;
	position: relative;
}

.notice-win {
	direction: ltr;
	width: 200px;
	color: #f1f1f1;
	font-size: 1.1em;
	/*padding: 25px 17px 15px 5px;*/
	position: absolute;
	top: 80px;
	left: 15px;
}

.notice-win .time {
	display:block;
	color: orange;
	margin: 15px 0;
}

.foot-notice {
	position: absolute;
	bottom: 25px;
	left: 150px;
	font-size: 15px;
	color: white;
}